import React from 'react';
import styled from 'styled-components';
import { Features } from './Features';
import dashboardImage1x from '../../../images/illustrations/dashboard@1x.png';
import dashboardImage2x from '../../../images/illustrations/dashboard@2x.png';
import { SectionTitleLight } from '../../../components/Text/SectionTitle';
import { Container } from '../../layout/Container';
import { EarnButton } from '../../Header/style';

export const AboutSection = () => (
  <View>
    <Container>
      <Features />
      <DashboardImageContainer
        data-aos="zoom-out-down"
        data-aos-duration="1000"
      >
        <DashboardLinkContainer href="https://app.truefi.io/" target="_blank">
          <DashboardImage
            src={dashboardImage1x}
            srcSet={dashboardImage2x}
            alt="dashboard"
          />
          <DashboardLinkBlock>
            <DashboardLinkTitle>Visit our app</DashboardLinkTitle>
            <DashboardEarnButton
              href="https://app.truefi.io"
              target="_blank"
              rel="noreferrer noopener"
            >
              Go to TrueFi
            </DashboardEarnButton>
          </DashboardLinkBlock>
        </DashboardLinkContainer>
      </DashboardImageContainer>
    </Container>
  </View>
);

const View = styled.section`
  padding: 124px 0 0;
  background: #fff;
  overflow: hidden;
`;

const DashboardImageContainer = styled.div`
  margin-top: 120px;

  @media (max-width: 767px) {
    margin-top: 64px;
  }
`;

const DashboardImage = styled.img`
  display: block;
  max-width: 100%;
  transform-origin: 50% 50%;
  transition: all 0.25s ease;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    max-width: 300px;
  }
`;

const DashboardLinkContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  
  &:hover,
  &:focus-visible {

    ${DashboardImage} {
      transform: scale(1.025);
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const DashboardLinkBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 24px 60px;
  background-color: #0B0E14;
  border-radius: 2px;
  z-index: 2;

  @media (max-width: 767px) {
    position: static;
    padding: 16px 32px;
  }
`;

const DashboardLinkTitle = styled(SectionTitleLight)`
  font-size: 22px;
  line-height: 32px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const DashboardEarnButton = styled(EarnButton)`
  margin-left: 24px;
`;
