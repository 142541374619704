import useSWR from 'swr';

const fetcher = (...args) => fetch(...args).then(res => res.json());
const CG_PRICE_URL = 'https://api.coingecko.com/api/v3/simple/price?ids=truefi&vs_currencies=usd';

export function useTruPrice() {
  const { data, error } = useSWR(CG_PRICE_URL, fetcher);
  const price = data?.truefi?.usd || 0;

  return {
    fetching: !data && !error,
    price,
    error,
  }
}
