import React from 'react';
import { Button, ButtonsRow, Link, LinkText, Title } from 'components/CtaSectionWrapper/CtaElements';
import { CtaSectionWrapper } from 'components/CtaSectionWrapper';

export const CtaSection = () => (
  <CtaSectionWrapper>
    <>
      <Title data-aos="fade-up" data-aos-delay="250">View opportunities on TrueFi</Title>
      <ButtonsRow>
        <Button
          href="https://app.truefi.io"
          target="_blank"
          rel="noreferrer noopener"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          Go to app
        </Button>
        <LinkText data-aos="fade-up" data-aos-delay="750">or <Link href="https://discord.com/invite/3tMyMqyqDj" target="_blank" rel="noopener noreferrer">Join the Community</Link></LinkText>
      </ButtonsRow>
    </>
  </CtaSectionWrapper>
);
