import useSWR from 'swr'
import { useQuery } from 'urql';
import { useMemo } from 'react';
import { ChainId } from '../constants/chain';
import { ADDRESSES } from '../constants/addresses';

const fetcher = (...args) => fetch(...args).then(res => res.json());

const COMPOUND_USDC_DATA_URL = 'https://api.compound.finance/api/v2/ctoken?addresses[]=0x39aa39c021dfbae8fac545936693ac917d5e7563';
const AAVE_GRAPH_URL = 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2'
const USDC_ADDRESS = ADDRESSES.usdc[ChainId.Mainnet]

const AAVEQuery = `{
  reserves (
    where: { underlyingAsset: "${USDC_ADDRESS.toLowerCase()}" },
    orderBy: "liquidityRate",
    orderDirection: "desc",
  ) {
    id
    name
    symbol
    liquidityRate
    underlyingAsset
  }
}`;

export function useDefiRates() {

  const { data, error } = useSWR(COMPOUND_USDC_DATA_URL, fetcher);
  const compoundUsdcRate = data?.cToken?.[0]?.supply_rate?.value || 0;

  const [ aaveQueryResult ] = useQuery({
    query: AAVEQuery,
    context: useMemo(() => ({ url: AAVE_GRAPH_URL }), []),
  });

  const aaveUsdcRate = useMemo(() => {
    if (!aaveQueryResult?.data?.reserves) return 0
    const reserves = aaveQueryResult?.data?.reserves;
    const aaveUsdc = reserves.filter(({ symbol }) => symbol === 'USDC');
    if (!aaveUsdc?.[0]?.liquidityRate) return 0
    // NOTE: why 27 dec places???
    const rate = aaveUsdc[0].liquidityRate / 10**10 / 10**10 / 10**7;
    return rate > 0 ? rate : 0;
  }, [aaveQueryResult.data?.reserves]);

  const rates = {
    compound: compoundUsdcRate,
    aave: aaveUsdcRate ? aaveUsdcRate : 0,
  }

  return {
    rates,
    isLoading: !error && !data,
    isError: error
  }
}
