import React from 'react';
import styled from 'styled-components';
import magnifyingGlassIcon from '../../../images/icons/magnifying-glass.svg';
import borrowersIcon from '../../../images/icons/borrowers.svg';
import groupIcon from '../../../images/icons/group.svg';

export const Features = () => (
  <Row>
    <div
      data-aos="fade-up"
    >
      <Feature
        icon={magnifyingGlassIcon}
        title="Maximum Transparency"
        text="Track every dollar loaned to borrowers, in real time."
      />
    </div>
    <div
      data-aos="fade-up"
      data-aos-delay="250"
    >
      <Feature
        icon={borrowersIcon}
        title="Diverse Opportunities"
        text='TrueFi serves borrowers and PMs across multiple verticals, including "real-world" use cases and crypto-focused institutions.'
      />
    </div>
    <div
      data-aos="fade-up"
      data-aos-delay="500"
    >
      <Feature
        icon={groupIcon}
        title="By & For Our Users"
        text="TRU holders govern the protocol, approve new PMs and borrowers, and manage key treasury & partnership decisions."
      />
    </div>
  </Row>
);

const Feature = ({ icon, title, text }) => (
  <FeatureContent>
    <Icon src={icon} />
    <div>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </div>
  </FeatureContent>
);

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  row-gap: 40px;

  @media (max-width: 1024px) {
    width: fit-content;
    flex-direction: column;
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    row-gap: 56px
  }
`;

const FeatureContent = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 360px;
`;

const Icon = styled.img`
  margin-right: 16px;
`;

const Title = styled.h3`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #000000;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 32px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 120%;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: #6C7893;

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
