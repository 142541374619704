import { useMemo } from 'react';
import { useQuery } from 'urql';
import { useMultiFarmRewardValue } from './useMultiFarmRewardValue';
import { SUBGRAPH_URLS } from '../constants/graphUrls';
import { DAY_IN_SECONDS, YEAR_IN_DAYS, YEAR_IN_SECONDS } from '../constants/units';

const endDate = Math.floor(Date.now() / 1000);

const LoansQuery = `query($poolAddress: String, $endDate: Int) {
  loans(where: { poolAddress: $poolAddress, endDate_gt: $endDate, status_lt: 3, status_gt: 0 }) {
    loanAddress
    startDate
    endDate
    amount
    debt
    APY
    term
    poolAddress
    decimals
    status
  }
}`;

const PoolsQuery = `query($poolAddress: String) {
  poolValues(where: { id: $poolAddress }) {
    id
    value
  }
}`;

export function usePoolApy() {
  const usdcPoolAddress = '0xA991356d261fbaF194463aF6DF8f0464F8f1c742'

  const [poolsQueryResult] = useQuery({
    query: PoolsQuery,
    variables: { poolAddress: usdcPoolAddress },
    context: useMemo(() => ({ url: SUBGRAPH_URLS.Pools }), []),
  });
  const poolValue = poolsQueryResult.data?.poolValues?.[0]?.value || 0;

  const [loansQueryResults] = useQuery({
    query: LoansQuery,
    variables: { poolAddress: usdcPoolAddress, endDate },
    context: useMemo(() => ({ url: SUBGRAPH_URLS.Loans }), []),
  });
  const loans = loansQueryResults?.data?.loans || [];

  const { yearlyRewardsValue, poolTokensStaked } = useMultiFarmRewardValue();
  const usdcPoolStaked = (poolTokensStaked ?? [])
    .find(({ id }) => id.substring(0, 42) === usdcPoolAddress.toLowerCase())?.totalStaked;


  const poolRewardsROI = (yearlyRewardsValue * 100) / (usdcPoolStaked / 10**6 ?? poolValue);

  const poolApy = useMemo(() => {
    let loanApy = 0;
    if (!(poolValue > 0)) return loanApy;

    loans.forEach((loan) => {
      const { debt, APY, decimals } = loan;
      const loanTokenPrice = calcLoanTokenPrice(loan);
      const realizedLoanValue = loanTokenPrice * debt;
      const percOfPool = (realizedLoanValue / 10 ** decimals) / poolValue;
      const loanWeightedApy = (APY * percOfPool) / 100;
      loanApy = loanWeightedApy + loanApy;
    });
    return loanApy + poolRewardsROI;
  }, [
    poolRewardsROI,
    JSON.stringify(loans.map(({ loanAddress }) => loanAddress))
  ]);

  return {
    isFetching: !(poolRewardsROI > 0) || !(loans?.length > 0),
    poolApy
  }
}

function calcLoanTokenPrice(loan): number {
  const now = new Date();
  const loanStart = new Date(loan?.startDate * 1000) || now;
  const apyNumber = loan.APY / 100;
  const term = (loan.term || YEAR_IN_SECONDS) / DAY_IN_SECONDS;

  let passed = (now.getTime() - loanStart.getTime()) / (1000 * DAY_IN_SECONDS);
  if (passed > term) passed = term;

  const totalMinted = 1 + (apyNumber * term) / YEAR_IN_DAYS / 100;
  const present = 1 + (apyNumber * passed) / YEAR_IN_DAYS / 100;
  return present / totalMinted;
}
