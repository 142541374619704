import React from 'react';
import launchIcon from '../../../icons/arrow-thin.svg';
import styled from 'styled-components';
import { BaseButtonLinkStyles, buttonPrimaryStyles } from '../../Button/Button';
import Typing from 'react-typing-animation';

export const HeroContent = () => (
  <HeroContentInner>
    <HeroTitle data-aos="fade-up">Make the most of your{' '}
      <Symbol>
        <Typing hideCursor loop>
          <span>USDC</span>
          <Typing.Delay ms={2000} />
          <Typing.Backspace count={5} />
          <span>USDT</span>
          <Typing.Delay ms={2000} />
          <Typing.Backspace count={5} />
          <span>TUSD</span>
          <Typing.Delay ms={2000} />
          <Typing.Backspace count={5} />
          <span>BUSD</span>
          <Typing.Delay ms={2000} />
          <Typing.Backspace count={5} />
        </Typing>
      </Symbol>
    </HeroTitle>
    <Text data-aos="fade-up" data-aos-delay="250">
      TrueFi is making credit more accessible, transparent, and programmable by bringing debt infrastructure on-chain.
    </Text>
    <ButtonContainer>
      <EarnButton
        href="https://app.truefi.io"
        target="_blank"
        rel="noreferrer noopener"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        Go to app
        <img src={launchIcon} />
      </EarnButton>
    </ButtonContainer>
  </HeroContentInner>
);

const Symbol = styled.div`
  width: 177px;
  height: 72px;

  @media (max-width: 990px) {
    width: 133px;
    height: 56px;
  }
`;

const HeroTitle = styled.h1`
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
  color: #fff;

  @media (max-width: 990px) {
    font-size: 48px;
    line-height: 56px;
  }

  span {
    color: #1a5aff;
  }

  & div {
    display: inline-block;
  }
`;
const HeroContentInner = styled.div`
  max-width: 500px;

  @media (max-width: 767px) {
    max-width: initial;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 990px) {
    flex-direction: column;
    align-items: center;
    max-width: 250px;
  }

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const EarnButton = styled(BaseButtonLinkStyles)`
  ${buttonPrimaryStyles};

  img {
    margin-left: 16px;
  }

  @media (max-width: 990px) {
    margin: 0 0 22px;
  }
`;

const Text = styled.p`
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px;
  color: #FFFFFF;

  @media (max-width: 990px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
