
export const SUBGRAPH_URLS = {
  LegacyLoans: 'https://api.thegraph.com/subgraphs/name/mikemccready/truefi-loanfactory',
  Loans: 'https://api.thegraph.com/subgraphs/name/mikemccready/truefi-loanfactory2',
  StkTru: 'https://api.thegraph.com/subgraphs/name/mikemccready/truefi-stktru',
  MultiFarmState: 'https://api.thegraph.com/subgraphs/name/mikemccready/truefi-multifarm',
  MultiFarm: 'https://api.thegraph.com/subgraphs/name/mikemccready/testing',
  Pools: 'https://api.thegraph.com/subgraphs/name/mikemccready/truefi-pools',
  Ragnarok: 'https://api.thegraph.com/subgraphs/name/mikemccready/ragnarok',
  RagnarokOptimism: 'https://api.thegraph.com/subgraphs/name/mikemccready/ragnarok-optimism',
  Beryllium: 'https://api.thegraph.com/subgraphs/name/miksujak/beryllium-mainnet',
  Structured: 'https://api.thegraph.com/subgraphs/name/nezouse/structured-portfolios-mainnet' 
}
