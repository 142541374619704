import { useMemo } from 'react';
import { useQuery } from 'urql';
import { useTruPrice } from './useTruPrice';
import { SUBGRAPH_URLS } from '../constants/graphUrls';
import { DAY_IN_SECONDS } from '../constants/units';

const MultiFarmStateQuery = `{
  multiFarmStates {
    id
    usdcShare
    totalShares
    duration
    totalDistribution
  }
}`;

const MultiFarmQuery = `{
  multifarms {
    id
    totalStaked {
      id
      totalStaked
    }
  }
}`;

export function useMultiFarmRewardValue() {
  const { price: truPrice } = useTruPrice();
  const [mfStateQueryResults] = useQuery({
    query: MultiFarmStateQuery,
    context: useMemo(() => ({ url: SUBGRAPH_URLS.MultiFarmState }), [])
  });

  const [mfQueryResults] = useQuery({
    query: MultiFarmQuery,
    context: useMemo(() => ({ url: SUBGRAPH_URLS.MultiFarm }), [])
  });

  const mfState = mfStateQueryResults.data?.multiFarmStates?.[0];
  const poolTokensStaked = mfQueryResults.data?.multifarms?.[0]?.totalStaked;

  if (!mfState || !poolTokensStaked) {
    return {
      isFetching: mfQueryResults.fetching,
      yearlyRewardsValue: 0,
      poolTokensStaked: []
    }
  }

  const { duration, usdcShare, totalDistribution, totalShares } = mfState;
  const durationDays = duration / DAY_IN_SECONDS;
  const totalDailyDistribution = totalDistribution / durationDays;
  const yearlyDistributionToFarm = (usdcShare * totalDailyDistribution * 365) / totalShares;
  const yearlyRewardsValue = (truPrice * yearlyDistributionToFarm) / 10**8;

  return {
    isFetching: mfQueryResults.fetching,
    yearlyRewardsValue,
    poolTokensStaked,
  }
}
