import React, { useEffect } from 'react';
import { Header } from '../Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HeroSection } from './HeroSection/HeroSection';
import { AboutSection } from './AboutSection/AboutSection';
import { IndicatorsSection } from './IndicatorsSection/IndicatorsSection';
import { LearnSection } from '../LearnSection';
import styled from 'styled-components';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import { CtaSection } from './CtaSection';

export const LandingPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: true,
    });
  }, []);
  return (
    <Wrapper>
      <SocialLinks />
      <Header />
      <HeroSection />
      <AboutSection />
      <IndicatorsSection />
      <LearnSection tokenInfo />
      <CtaSection />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
