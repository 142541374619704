import { BigNumber, utils, constants } from 'ethers';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import { SUBGRAPH_URLS } from '../constants/graphUrls';

const LegacyLoansQuery = `{
  loans(where: { status: 2 }) {
    loanAddress
    startDate
    endDate
    amount
    debt
    status
  }
}`;

const LoansQuery = `{
  loans(where: { status: 3 }) {
    loanAddress
    startDate
    endDate
    amount
    debt
    decimals
    status
  }
}`;

export function useInterestEarned() {
  const [loansQueryResults] = useQuery({
    query: LoansQuery,
    context: useMemo(() => ({ url: SUBGRAPH_URLS.Loans }), []),
  });
  const loans = loansQueryResults?.data?.loans || [];

  const [legacyLoansQueryResults] = useQuery({
    query: LegacyLoansQuery,
    context: useMemo(() => ({ url: SUBGRAPH_URLS.LegacyLoans }), []),
  });
  const legacyLoans = legacyLoansQueryResults?.data?.loans || [];

  const interestEarned = useMemo(() => {
    let totalPrincipal = constants.Zero;
    let totalDebt = constants.Zero;

    legacyLoans.forEach(({ amount, debt }) => {
      totalPrincipal = totalPrincipal.add(BigNumber.from(amount));
      totalDebt = totalDebt.add(BigNumber.from(debt));
    });
    loans.forEach(({ amount, debt, decimals }) => {
      const decimalOffset = 18 - decimals;
      totalPrincipal = totalPrincipal.add(BigNumber.from(amount).mul(10 ** decimalOffset));
      totalDebt = totalDebt.add(BigNumber.from(debt).mul(10 ** decimalOffset));
    });

    return totalDebt.sub(totalPrincipal);
  }, [
    JSON.stringify(loans.map(({loanAddress}) => loanAddress)),
    JSON.stringify(legacyLoans.map(({loanAddress}) => loanAddress)),
  ]);

  return {
    isFetching: !interestEarned.gt(0) || !(loans.length > 0) || !(legacyLoans.length > 0),
    interestEarned: utils.formatUnits(interestEarned)
  }
}
