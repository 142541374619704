import React from 'react';
import styled from 'styled-components';
import truefiLogo from '../../../images/adrian/logos/truefi-logo-dark.svg';
import aaveLogo from '../../../images/adrian/logos/aave.svg';
import curveLogo1x from '../../../images/adrian/logos/curve@1x.png';
import curveLogo2x from '../../../images/adrian/logos/curve@2x.png';
import compoundLogo1x from '../../../images/adrian/logos/compound@1x.png';
import compoundLogo2x from '../../../images/adrian/logos/compound@2x.png';

import { useDefiRates } from '../../../hooks/useDefiRates';
import { usePoolApy } from '../../../hooks/usePoolApy';

export const HeroStatistics = () => {
  const { rates } = useDefiRates();
  const { isFetching, poolApy } = usePoolApy();

  if (isFetching || !(poolApy > 0)) {
    return <View />;
  }
  return (
    <div data-aos="fade-up" data-aos-offset="-750">
      <View>
        <Headline>TrueFi returns <span>vs</span> Top DeFi protocols</Headline>
        <Header>
          <div>
            <PercentagePrimary>{poolApy.toFixed(2)}%</PercentagePrimary>
            <LabelPrimary>USDC APY*</LabelPrimary>
          </div>
          <div>
            <Logo src={truefiLogo} alt="truefi logo" />
          </div>
        </Header>
        <ContentWrapper>
          <List>
            {rates.aave && rates.aave > 0
              ? (
                <ListItem>
                  <div>
                    <PercentageSecondary>
                      {(rates.aave * 100).toFixed(2)}%
                    </PercentageSecondary>
                    <LabelSecondary>USDC APY</LabelSecondary>
                  </div>
                  <img src={aaveLogo} alt="aave logo" />
                </ListItem>
              )
              : <></>
            }

            {rates.curve && rates.curve > 0
              ? (
                <ListItem>
                  <div>
                    <PercentageSecondary>
                      {(rates.compound * 100).toFixed(2)}%
                    </PercentageSecondary>
                    <LabelSecondary>USDC APY</LabelSecondary>
                  </div>
                  <CurveLogo src={curveLogo1x} srcSet={curveLogo2x} alt="curve logo" />
                </ListItem>
              )
              : <></>
            }

            {rates.compound && rates.compound > 0
              ? (
                <ListItem>
                  <div>
                    <PercentageSecondary>
                      {(rates.compound * 100).toFixed(2)}%
                    </PercentageSecondary>
                    <LabelSecondary>USDC APY</LabelSecondary>
                  </div>
                  <CompoundLogo src={compoundLogo1x} srcSet={compoundLogo2x} alt="compound logo" />
                </ListItem>
              )
              : <></>
            }
          </List>
        </ContentWrapper>
      </View>
    </div>
  );
};

const View = styled.div`
  max-width: 364px;
  min-width: 270px;
  width: 100%;
  margin-left: 48px;

  @media (max-width: 767px) {
    margin: 48px 0 0;
  }
`;

const Headline = styled.p`
  margin-bottom: 8px;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;

  span {
    color: #7A859E;
    font-style: italic;
  }

  @media (max-width: 990px) {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 19px;
  background: #FFFFFF;
  border-radius: 2px;

  @media (max-width: 990px) {
    padding: 16px;
  }
`;

const Logo = styled.img`
  @media (max-width: 990px) {
    max-width: 102px;
  }
  `;

const PercentagePrimary = styled.p`
  font-weight: 600;
  font-size: 36px;
  line-height: 35px;
  color: #1A5AFF;

  @media (max-width: 990px) {
    font-size: 32px;
    line-height: 32px;
  }
  `;

const LabelPrimary = styled.p`
  margin-top: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #43607B;
  `;

const ContentWrapper = styled.div`
  padding: 0 16px;
  `;

const List = styled.ul`
  background: #1D273B;
  `;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 81px;
  padding: 12px;

  @media (max-width: 990px) {
    height: 72px;
  }
`;

const PercentageSecondary = styled.p`
  margin-bottom: 3px;
  font-size: 22px;
  line-height: 35px;
  color: #FFFFFF;
  opacity: 0.8;
`;

const LabelSecondary = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #7A859E;
`;

const CurveLogo = styled.img`
  width: 91px;
`;

const CompoundLogo = styled.img`
  width: 95px;
`;
