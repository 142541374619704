import numeral from 'numeral';
import React from 'react';
import styled from 'styled-components';
import { useInterestEarned } from '../../../hooks/useInterestEarned';
import { Container } from '../../layout/Container';
import { SectionBright } from '../../layout/SectionBright';

export const IndicatorsSection = () => {
  const { isFetching: fetchingInterest, interestEarned } = useInterestEarned();

  return (
    <View>
      <Container>
        <Row>
          {fetchingInterest
            ? <></>
            : (
              <div data-aos="fade-up" data-aos-delay="250">
                <Amount>$ {numeral(interestEarned).format('0,0')} <span>USD</span></Amount>
                <Label>Total Interest Earned</Label>
              </div>
            )
          }
        </Row>
      </Container>
    </View>
  );
};

const View = styled(SectionBright)`
  padding: 32px 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;

  @media(max-width: 1100px) {
    justify-content: center;

    & > div + div {
      margin-left: 80px;
    }
  }

  @media(max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 0 8px;

    & > div {
      width: 100%;
      text-align: center;
    }

    & > div + div {
      padding-top: 24px;
      margin: 24px 0 0;
      border-top: 1px solid rgba(208, 214, 230, 0.1);
    }
  }
`;

const Amount = styled.p`
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: #FFFFFF;

  span {
    margin-left: 8px;
    font-size: 28px;
    line-height: 41px;
    color: #FFFFFF;
  }

  @media(max-width: 1100px) {
    font-size: 40px;
    line-height: 120%;

    span {
      font-size: 14px;
      line-height: 120%;
    }
  }

  @media(max-width: 600px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const Label = styled.p`
  margin-top: 8px;
  font-size: 20px;
  line-height: 31px;
  color: #FFFFFF;

  @media(max-width: 1100px) {
    font-size: 14px;
    line-height: 120%;
  }
`;
