import React from 'react';
import styled from 'styled-components';
import { communitySocials } from 'components/Footer/socials';

export const SocialLinks = ({ light }) => (
  <List light={light}>
    {communitySocials.map((item, index) => <ListItem key={index} item={item} index={index} light={light} />)}
  </List>
);

const ListItem = ({ item, index, light }) => {
  const { link, icon } = item;
  const Icon = icon;

  return (
    <Item data-aos="fade-up" data-aos-delay={(index * 150 + 1000).toString()} light={light}>
      <Link href={link} target="_blank" rel="noopener noreferrer">
        <Icon color="fillColor" />
      </Link>
    </Item>
  );
};

const List = styled.ul`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 10;
  background-color: ${({ light }) => light ? '#ffffff' : '#0B0E14'};
  border-radius: 4px 0 0 4px;
  transform: translateY(-50%);
  box-shadow: 0 75px 48px rgba(6, 20, 57, 0.05), 0 8px 26px rgba(6, 20, 57, 0.05), 0 4px 16px rgba(6, 20, 57, 0.03);
  
  @media (max-width: 767px) {
    position: static;
    top: 0;
    flex-direction: row;
    justify-content: center;
    transform: unset;
  }
`;

const Item = styled.li`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  & + & {
    margin-top: 8px;

    @media (max-width: 767px) {
      margin: 0 0 0 24px;
    }
  }

  svg {
    fill: ${({ light }) => light ? '#60697C' : '#ffffff'};
  }

  &:hover svg {
    fill: #0749F3
  }
`;

const Link = styled.a`
  width: 32px;
  height: 32px;
  opacity: 0.8;
`;
