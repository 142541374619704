import React from 'react';
import styled from 'styled-components';
import { HeroContent } from './HeroContent';
import { HeroStatistics } from './HeroStatistics';
import backgroundImage from '../../../images/backgrounds/ellipse.svg';
import { Container } from '../../layout/Container';
import { trustedByCompany } from '../../../constants/trustedByCompany';
import { LogoBox } from '../../LogoBox';

export const HeroSection = () => (
  <Hero>
    <Container>
      <Row>
        <HeroContent />
        <HeroStatistics />
      </Row>
      <StyledHeroLogoBox logo={trustedByCompany} title="Trusted by..." delay={1000} />
    </Container>
  </Hero>
);

const Hero = styled.section`
  position: relative;
  padding: 96px 0 0;
  background: #0B0E14 url(${backgroundImage}) no-repeat;
  background-size: cover;

  @media (max-width: 900px) {
    padding: 64px 0 0;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 36px 88px 24px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const StyledHeroLogoBox = styled(LogoBox)`
  padding: 48px 60px 56px;
  transform: translateY(42px);
  box-shadow: 0 24px 60px rgba(2, 10, 33, 0.04);

  @media (max-width: 1024px) {
    padding: 32px;
    transform: translateY(68px);
  }

  @media (max-width: 767px) {
    padding: 24px;
  }
`;
