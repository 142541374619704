import React from 'react';
import styled from 'styled-components';

import '../components/layout/layout.css';

import { LandingPage } from '../components/LandingPage';
import SEO from '../components/seo';
import { Footer } from '../components/Footer/Footer';

const LandingPageContainer = styled.div`
  background-color: black !important;
  position: relative;
`;

function IndexPage() {
  return (
    <LandingPageContainer>
      <SEO title="TrueFi - Credit infrastructure for crypto" />
      <LandingPage />
      <Footer />
    </LandingPageContainer>
  );
}

export default IndexPage;
